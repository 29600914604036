<template>
  <div class="content-wrapper">
    <PageHeader
      screen-name="Registro de Vacinação"
      :link-items="linkItems"
    />
    <main class="card">
      <validation-observer ref="selectRules">
        <b-row class="pt-2 px-2">
          <b-col
            lg="4"
            md="4"
            sm="12"
          >
            <b-form-group
              label="Unidade Operacional"
              label-for="unidade-operacional-input"
              label-class="font_size_label"
            >
              <validation-provider
                #default="{ errors }"
                name="unidade"
                rules="required"
              >
                <v-select
                  id="unidade-operacional-input"
                  v-model="getSearch.unidade"
                  :options="unidadeSelect"
                  label="descricao_unidade"
                  placeholder="Selecione uma unidade"
                  :clearable="true"
                  @input="searchEmpresa"
                >
                  <span slot="no-options">Nenhuma opção selecionável.</span>
                </v-select>
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >
                  Este campo é de preenchimento obrigatório.
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            lg="4"
            md="4"
            sm="12"
          >
            <b-form-group
              label="Empresa"
              label-for="empresa-input"
              label-class="font_size_label"
            >
              <validation-provider
                #default="{ errors }"
                name="empresa"
                rules="required"
              >

                <v-select
                  id="select-empresa"
                  v-model="getSearch.empresa"
                  variant="custom"
                  item-text="descricao"
                  item-value="id_empresa"
                  label="nome_empresa"
                  placeholder="Selecione uma empresa"
                  :values="empresaSelect"
                  :options="empresaSelect"
                  :disabled="disabled.empresa"
                  @input="searchCampanha"
                >

                  <span slot="no-options">Nenhuma opção selecionável.</span>

                  <template #option="{nome_empresa, documento_formatado}">
                    <strong>{{ nome_empresa }}</strong><br>
                    <span>{{ documento_formatado }}</span>
                  </template>
                </v-select>
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >
                  Este campo é de preenchimento obrigatório.
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            lg="4"
            md="4"
            sm="12"
          >
            <b-form-group
              label="Campanha"
              label-for="campanha-input"
              label-class="font_size_label"
            >
              <validation-provider
                #default="{ errors }"
                name="campanha"
                rules="required"
              >
                <v-select
                  id="select-campanha"
                  v-model="getSearch.campanha"
                  variant="custom"
                  label="descricao_campanha"
                  placeholder="Selecione uma campanha"
                  :values="campanhaSelect"
                  :options="campanhaSelect"
                  :disabled="disabled.campanha"
                  @input="searchEmpresaEndereco"
                >
                  <span slot="no-options">Nenhuma opção selecionável.</span>
                </v-select>
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >
                  Este campo é de preenchimento obrigatório.
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="pt-2 px-2" v-if="showEndereco">
          <b-col lg="8" md="8" sm="12">
            <b-form-group
            label="Endereço"
            label-for="endereco-input"
            label-class="font_size_label"
            >
            <validation-provider
                  #default="{ errors }"
                  name="endereco"
                  rules="required"
                  >
                <v-select
                id="select-endereco"
                variant="custom"
                label="id_empresa_endereco"
                placeholder="Selecione um endereço"
                v-model="getSearch.endereco"
                :values="enderecoSelect"
                :options="enderecoSelect"
                :disabled="disabled.endereco"
                >
              <template #option="data" >
                  <span>
                      <b>{{ data.descricao }}</b><br/>
                      {{ formatEndereco(data) }}
                  </span>
              </template>
              <template #selected-option="data">
                  <span>
                      <b>{{ data.descricao }}</b><br/>
                      {{ formatEndereco(data) }}
                  </span>
              </template>
                <span slot="no-options">Nenhuma opção selecionável.</span>
                </v-select>
                <small v-if="errors[0]" class="text-danger">
                  Este campo é de preenchimento obrigatório.
              </small>
          </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="justify-content-center pb-2">
          <b-button
            type="reset"
            class="mr-2 cor_botao"
            variant="outline-primary"
            @click="clearSearch"
          >
            <feather-icon
              icon="XIcon"
              class="mr-50"
            />
            <span class="align-middle">Limpar</span>
          </b-button>
          <b-button
            variant="primary-button"
            @click="redirectToList"
          >
            <feather-icon
              icon="CheckIcon"
              class="mr-50"
            />
            <span class="align-middle">Selecionar empresa</span>
          </b-button>

        </b-row>
      </validation-observer>
    </main>
  </div>
</template>

<script>
import {
  BCol, BRow, BFormGroup, BButton, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'

export default {
  title: 'Selecionar empresa',

  components: {
    BCol,
    BRow,
    BFormGroup,
    BButton,
    vSelect,
    PageHeader,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Vacinação',
          routeName: 'vacinacao-list',
        },
        {
          name: 'Registro de vacinação',
          active: true,
        },
      ],
      alert: {
        errorMessage: '',
        show: false,
      },
      settings: {
        maxScrollbarLength: 70,
        wheelSpeed: 0.2,
        wheelPropagation: false,
      },
      required,
      timeID: null,
      filtering: false,
      loadingData: false,
      without: false,
      searchTerm: '',
      disabled: {
        empresa: true,
        campanha: true,
        endereco: true,
      },
      unidadeSelect: [],
      empresaSelect: [],
      campanhaSelect: [],
      enderecoSelect: [],
      showEndereco: false
    }
  },

  computed: {
    getSearch() {
      return this.$store.getters['vacinacaoState/getSearch']
    },
  },

  mounted() {
    this.clearSearch()
    this.loadInfo()
  },

  methods: {
    async loadInfo() {
      const parameters = {
        ativo: true,
      }
      await this.$http.get(this.$api.unidade(), { params: parameters }).then(({ data }) => {
        this.unidadeSelect = data
      })
    },
    async searchEmpresa() {
      this.disabled.empresa = true
      this.disabled.campanha = true
      this.getSearch.empresa = ''
      this.getSearch.campanha = ''
      this.empresaSelect = []
      this.disabled.endereco = true;
      this.showEndereco = false;
      this.getSearch.endereco = '';
      this.enderecoSelect = [];

      await this.$http.get(this.$api.empresaUnidadeParameter(this.getSearch.unidade.id_unidade)).then(({ data }) => {
        const empresas = []
        data.map(item => {
          empresas.push({
            id_empresa: item.id_empresa,
            nome_empresa: item.nome_empresa,
            documento_empresa: item.documento,
            documento_formatado: this.formatDocument(item.documento),
          })
        })
        empresas.sort(this.orderCompanyList)
        this.empresaSelect = empresas
        this.disabled.empresa = false
      })
    },
    async searchCampanha() {
      this.disabled.campanha = true
      this.getSearch.campanha = ''
      this.campanhaSelect = []
      this.disabled.endereco = true;
      this.showEndereco = false;
      this.getSearch.endereco = '';
      this.enderecoSelect = [];

      const parameters = {
        situacao: ['Concluída', 'Em andamento'],
        id_unidade: this.getSearch.unidade.id_unidade,
      }

      await this.$http.get(this.$api.campanhaEmpresaParameter(this.getSearch.empresa.id_empresa), { params: parameters }).then(({ data }) => {
        this.campanhaSelect = data
        this.disabled.campanha = false
      })
    },
    async searchEmpresaEndereco(){
        this.disabled.endereco = true;
        this.showEndereco = false;
        this.getSearch.endereco = '';
        this.enderecoSelect = [];

        if(this.getSearch.campanha.tipo == 'Nacional') {
            this.showEndereco = true;

            await this.$http.get(this.$api.getEmpresaEnderecoComAdesao(this.getSearch.empresa.id_empresa, this.getSearch.campanha.id_campanha, this.getSearch.unidade.id_unidade)).then(({ data }) => {
                this.enderecoSelect = data;
                this.disabled.endereco = false;
            });
        }
    },
    redirectToList() {
      this.$refs.selectRules.validate().then(success => {
        if (success) {
          this.$router.push(
            {
              name: 'resumo-registro-vacinacao',
            },
          )
        }
      })
    },
    clearSearch() {
      this.$store.commit('vacinacaoState/CLEAR_SEARCH')
    },
    orderCompanyList(a, b) {
      if (a.nome_empresa.toLowerCase() > b.nome_empresa.toLowerCase()) return 1

      if (a.nome_empresa.toLowerCase() < b.nome_empresa.toLowerCase()) return -1

      return 0
    },

    formatDocument(document) {
      if (document.length === 14) {
        return this.$options.filters.VMask(document, '##.###.###/####-##')
      }
      if (document.length === 11) {
        return this.$options.filters.VMask(document, '###.###.###-##')
      }
      return document
    },
    formatEndereco(endereco) {
        let descricao = '';

        if(endereco.logradouro) {descricao += `${endereco.logradouro}, ` };
        if(endereco.numero) { descricao += `${endereco.numero}, ` };
        if(endereco.bairro) { descricao += `${endereco.bairro}, ` };
        if(endereco.nome_cidade) { descricao += `${endereco.nome_cidade}, ` };
        if(endereco.uf) { descricao += `${endereco.uf}` };

        return descricao;
    },
  },
}
</script>

<style>
.font_size_label {
    font-size: 15px;
    margin-top: -5px;
}

.custom {
    padding: 5px 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    font-weight: 400;
}

.mono {
    background-color: #f3eeff;
    color: #8c65e2;
}

.multi {
    background-color: #e3f6f4;
    color: #21a696;
}

.cor_botao {
    color: #2772C0 !important;
    border: 1px solid #2772C0 !important;
}

.altura_max_scrollbar {
    max-height: 100px;
    padding-right: 15px;
}

.comprimento_maximo_celulas {
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
}

.hidden_overflow_tabela {
    overflow: hidden;
}
</style>
